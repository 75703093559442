<template>
<div>
  <Loading :isLoading="isLoading" />
  <div>
    <p style="padding:10px 10px 0px 10px;font-size:20px;color:#810042;font-weight:700;font-family:Lato;">Solicitudes Rechazadas</p>
  </div>
  <TableComponent :key="render" :columns="columns" :rows="rows" :rangeInput="true" :exportInput="true"/> 
</div>
</template>

<script>
import { onMounted, getCurrentInstance, ref } from 'vue';
import { useRouter } from 'vue-router';

import Header from '@/components/Analyst/Header.vue';
import MenuLateral from '@/components/Analyst/MenuLateral.vue';
import Loading from '@/components/Loading/VueLoading.vue';
import '@/assets/css/tailwind.css';
import {getTokenDecoden} from "@/helpers/tokenauth";

import Affiliated from '@/classes/Affiliated.js';

import TableComponent from "@/components/Forms/Tables/TableComponent";

import Swal from "sweetalert2";

export default {

  name: 'SolicitudesAceptadas',

  setup() {
    const isLoading =  ref(false);
    const apiToken =  ref("");
    const personId =  ref("");
    const requests =  ref([]);
    const allRequests =  ref([]);
    const requestsCount =  ref(0);
    const associates =  ref([]);
    const userId =  ref(0);
    const name =  ref("");
    const paternal_name =  ref("");
    const router = useRouter();

    let app = getCurrentInstance();
    const affiliatedObject = ref(null);
    let render = ref(false);
    let columns = ref([]);
    let rows = ref([]);
    
    onMounted(async () => {
      isLoading.value = true;
      affiliatedObject.value = new Affiliated();
      app = app.appContext.config.globalProperties;
      loadInfo();
      await getAssociates();
      await getRequests();
    });

    const getColumns = () => {
      columns.value = [
        { headerName: "RFC", field: "rfc", headerCheckboxSelection: true, minWidth: 200, resizable: true, sortable: true, filter: true, checkboxSelection: true,},
        { headerName: "Nombre", field: "name" },
        { headerName: "Telefono", field: "phone" },
        { headerName: "Correo", field: "email" },
        { headerName: "Monto a ahorrar", field: "savingAmount" },
        { headerName: "Puesto/Ocupación", field: "occupation" },
        { headerName: "Fecha de registro", field: "date" },
        { headerName: "Fecha de pago", field: "date_enroll_payment_fee" },
        { headerName: "Metodo de pago", field: "method_enroll_payment_fee" },
        { headerName: "Usuario asignado", field: "asigned_name" },
        { headerName: "Organización", field: "organization" },
        { headerName: "Ver informacion", field: "view", filter: false, cellRenderer: params => {
          
          const button = document.createElement("label");
          button.innerText = "Ver";
          button.className += "bg-cherryColor text-white focus:bg-blue-700 px-10 py-1.5 mx-auto rounded-md cursor-pointer text-base";
          button.addEventListener("click", e => {
            e.preventDefault();
            showRequest(params.value);
          });
          return button;
        }}
      ];
    }

    const getAsignedUser = (value) => {
      var asociate = associates.value.filter((associate) => associate.id == value)
      if(asociate.length > 0){
        return `${asociate[0].name.toUpperCase()} ${asociate[0].paternal_name.toUpperCase()}`;
      }else{
        return (value == userId)? `${name.value.toUpperCase()} ${paternal_name.value.toUpperCase()}`: '';
      }
    }

    const getRequests = async() => {
      return await getRejectedRequests().then((response) => {
        render.value = true;
      });
    }

    const loadInfo = () => {
      var auth = getTokenDecoden();
      apiToken.value = sessionStorage.getItem("login");
      personId.value = auth.obj.personnel_info['id'];
      userId.value = auth.obj['id'];
      name.value = auth.obj['name'];
      paternal_name.value = auth.obj['paternal_name'];
    }

    const getAssociates = async() => {
      var swal = require('sweetalert2');
      affiliatedObject.value.getAllAnalysts(apiToken.value).then(response => {
        associates.value = response.data.response.analysts;
      }).catch(err => {
        isLoading.value = false;
        Swal.fire({
          title: "Aviso",
          text: "Ocurrió un error al obtener los asociados",
          icon: "error",
          confirmButtonColor: '#FEB72B',
        });
      });
    }

    const convertListToArray = (list) => {
      let index = [];
      let array = [];
      for (var i in list) {
        index.push(i);
      }

      for (let i = 0; i < index.length; i++) {
        array.push(list[index[i]]);
      }

      return array;
    }

    const getFinalArrayList = (array) => {
      let finalArray = [];
      console.log("array; ", array);
      for (let i = 0; i < array.length; i++) {
        let contact_info = convertListToArray(array[i].admission_information.contact_info_attributes);

        var occupation = array[i].admission_information.work_info_attributes.occupation;
        var laboralStatus = array[i].admission_information.work_info_attributes.laboral_status;
        occupation = app.$filters.formatCamelize(laboralStatus.toLowerCase() == "jubilado" ? "jubilado" : occupation);

        finalArray.push({
          id: array[i].id,
          rfc: array[i].admission_information.rfc,
          name: app.$filters.formatCamelize(`${array[i].admission_information.name} ${array[i].admission_information.middle_name} ${array[i].admission_information.paternal_name} ${array[i].admission_information.maternal_name}`),
          completeName: app.$filters.formatCamelize(`${array[i].admission_information.name} ${array[i].admission_information.paternal_name}`),
          phone: app.$filters.formatPhone(contact_info.filter(({contact_type}) => contact_type === 'celular').length > 0 ? contact_info.filter(({contact_type}) => contact_type === 'celular')[0].value : "1111111111"),
          email: contact_info.filter(({contact_type}) => contact_type === 'correo').length > 0 ? contact_info.filter(({contact_type}) => contact_type === 'correo')[0].value : "correo@gmail.com",
          savingAmount: app.$filters.formatCurrency(array[i].admission_information.saving_registration_attributes.amount == "" ? "0" : array[i].admission_information.saving_registration_attributes.amount),
          occupation: occupation,
          date : app.$filters.formatDate(array[i].created_at),
          status: array[i].status,
          date_enroll_payment_fee: app.$filters.formatDate(array[i].admission_information.enrollment_fee_payment_date),
          method_enroll_payment_fee: array[i].admission_information.enrollment_fee_method,
          asigned_name: getAsignedUser(array[i].admission_analyst_id),
          organization: array[i].person.user.organization_list.join(),
          view: array[i].id
        })
      }
      return finalArray;
    }

    const getRejectedRequests = async() => {
      console.log("=>", personId.value);
      isLoading.value = true;
      return await affiliatedObject.value.getPendingRequests(apiToken.value, personId.value).then(response => {
        let finalArray = (response.data.response.admission_request != undefined)? getFinalArrayList(response.data.response.admission_request): [];
        requests.value = finalArray.filter(request => request.status.toLowerCase() === "rechazada").reverse();
        allRequests.value = finalArray.filter(request => request.status.toLowerCase() === "rechazada").reverse();
        requestsCount.value = requests.value.length;
        rows.value = requests.value;
        getColumns();
        isLoading.value = false;
    
      }).catch(err => {
        console.log("Error", err);
        requests.value = [];
        requestsCount.value = 0;
        isLoading.value = false;
      });
    }

    const showRequest = (id_admission_request) => {
      router.push( { path: "/analyst/cuenta/revision-solicitudes-ingreso/"+id_admission_request});
    }

    return {
      render,
      columns,
      rows
    }
  },
  components: {
    Header,
    Loading,
    MenuLateral,
    TableComponent
  }
}
</script>

<style scoped>
.main-layout {
  background:red;
}
.new-request {
  background: red;
}
table, th, td {
  border-collapse: collapse;
}
th, td {
  padding: 15px 10px 15px 10px !important;
}
table, th, td {
  border-bottom: 1px solid #85C875;
}
table thead tr th {
  border-bottom: 1px solid #85C875 !important;
}
tr {
  color: #174A84;
  font-family:lato;
  font-size: 12px;
}
#menu ul {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    width: 200px;
    font-family: Arial, sans-serif;
    font-size: 11pt;
}

#menu ul {
  width: 100%;
}
#menu ul li {
  font-family: "Roboto";
  border-bottom: 1px solid #85C875;
  color:#174A84;
  display: block; 
  padding-bottom: 0;
  padding-top: 10px;
  padding-left: 30px;
}
.buttonAdd {
    background-color: #4CAF50; /* Green */
    border: none;
    border-radius: 5px;
    color: white;
    padding: 1px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 13px;
    font-family:Lato;
    widows: 10px;
    cursor: pointer;
    background-color: #174A84;
}
/* Sort Table */
.arrow_down {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAaCAYAAABPY4eKAAAAAXNSR0IArs4c6QAAAvlJREFUSA29Vk1PGlEUHQaiiewslpUJiyYs2yb9AyRuJGm7c0VJoFXSX9A0sSZN04ULF12YEBQDhMCuSZOm1FhTiLY2Rky0QPlQBLRUsICoIN/0PCsGyox26NC3eTNn3r3n3TvnvvsE1PkwGo3yUqkkEQqFgw2Mz7lWqwng7ztN06mxsTEv8U0Aam5u7r5EInkplUol/f391wAJCc7nEAgE9Uwmkzo4OPiJMa1Wq6cFs7Ozt0H6RqlUDmJXfPIx+qrX69Ti4mIyHA5r6Wq1egND+j+IyW6QAUoul18XiUTDNHaSyGazKcZtdgk8wqhUKh9o/OMvsVgsfHJy0iWqVrcQNRUMBnd6enqc9MjISAmRP3e73T9al3XnbWNjIw2+KY1Gc3imsNHR0YV4PP5+d3e32h3K316TySQFoX2WyWR2glzIO5fLTSD6IElLNwbqnFpbWyO/96lCoai0cZjN5kfYQAYi5H34fL6cxWIZbya9iJyAhULBHAqFVlMpfsV/fHxMeb3er+Vy+VUzeduzwWC45XA4dlD/vEXvdDrj8DvURsYEWK3WF4FA4JQP9mg0WrHZbEYmnpa0NxYgPVObm5teiLABdTQT8a6vrwdRWhOcHMzMzCiXlpb2/yV6qDttMpkeshEzRk4Wo/bfoe4X9vb2amzGl+HoXNT29vZqsVi0sK1jJScG+Xx+HGkL4Tew2TPi5zUdQQt9otPpuBk3e0TaHmMDh1zS7/f780S0zX6Yni+NnBj09fUZUfvudDrNZN+GkQbl8Xi8RLRtHzsB9Hr9nfn5+SjSeWUCXC7XPq5kw53wsNogjZNohYXL2EljstvtrAL70/mVaW8Y4OidRO1/gwgbUMvcqGmcDc9aPvD1gnTeQ+0nmaInokRj0nHh+uvIiVOtVvt2a2vLv7Ky0tL3cRTXIcpPAwMDpq6R4/JXE4vFQ5FI5CN+QTaRSFCYc8vLy1l0rge4ARe5kJ/d27kYkLXoy2Jo4C7K8CZOsEBvb+9rlUp1xNXPL7v3IDwxvPD6AAAAAElFTkSuQmCC')
}
.arrow_up {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAaCAYAAACgoey0AAAAAXNSR0IArs4c6QAAAwpJREFUSA21Vt1PUmEYP4dvkQ8JFMwtBRocWAkDbiqXrUWXzU1rrTt0bdVqXbb1tbW16C9IBUSmm27cODdneoXjputa6069qwuW6IIBIdLvdaF4OAcOiGeDc87zPs/vd57P96WpFq7p6enbGo1mjKZpeTabjU1MTCRagGnOZHFxcXxtbe1XKpUq7+zslJeXl//Mz8+Hy+Uy3RxSE9qTk5M3otFooVQqgef4Wl9f343FYoEmoISrxuNxFX5f9vb2jhn/PxUKhfLS0tIPfFifUESRUMV8Pv/M6XReRm5rTGQyGeXxeGxYe1ezeBpBOBx2rKysbO7v79d4Wy3Y2Nj4GQqFbgnhaugxwiuGJx99Pp9FLBbXxYTXvTqd7v3MzIy6riIWGxJnMpl7AwMD14xGYyMsSq1WUyQdUqn0eSPlusQIsbGrq+vl4OCgvhFQZd1utyv1en0gEolcqsi47nWJlUrlG5fLZVcoFFy2nDKSDpIWlUoVTCQSEk4lCHmJMZ2GTCbTiMVikfIZ88l7enoos9l8dXt7+z6fDicxSJUokqDX6xXcl2wCROoc0vQCWL3sNfLOSdzR0fHY4XC4tVotl40gmVwup9xuN4OQv+UyqCFGH9rg7SOGYVRcBs3IEG4J0nVnamrqOtvuBDGGgQg9+wHFcVEi4a0LNkbdd6TrPKo8ODc311mteIIYjT/a398/jK+s1jnVM0kXoufCFvq0GuiIGEVgQIhfoygM1QrteEa9dAL7ITiYCt4RMabOK5AyKKzKWtvupLcRciu8D5J0EuDDPyT/Snd39yh6VtY2NhYQSR9G79Ds7OxdskRjEyAufvb7/cPoO5Z6e1+xtVKrq6vfcFzyi/A3ZrPZ3GdNSlwgo5ekE4X2RIQGf2C1WlufFE0GBeGWYQ8YERWLxQtnUVB830MKLZfL9RHir8lkssCn2G751tZWEWe03zTKm15YWPiEiXXTYDB0Ig/t7yd8PRws4EicwWHxO4jHD8/C5HiTTqd1BwcHFozKU89origB+y/kmzgYpgOBQP4fGmUiZmJ+WNgAAAAASUVORK5CYII=')
}
.arrow {
  float: right;
  width: 12px;
  height: 15px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: bottom;
}
th.active {
  color: #000;
}
th.active .arrow {
  opacity: 1;
}
.arrow {
  display: block;
  width: 0;
  height: 0;
  margin-left: 0px;
  margin-top:7px;
  opacity: 0.66;
}
.arrow.asc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid #174A84;
}
.arrow.dsc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #174A84;
}
.arrow-right {
  background: #000;
  height: 3px;
  width: 30px;
  margin: 0 auto;
  position: relative;
  cursor: pointer;
}
.pagination {
  justify-content: center !important;
}
.number {
  display: inline-block;
  padding: 4px 10px;
  color: #FFF;
  border-radius: 4px;
  background: #44475C;
  margin: 0px 5px;
  cursor: pointer;
}
.number:hover, .number.active {
  background: #717699;
}
select {
  border: 1px solid #174A84;border-radius:5px;padding:3px;
}
@media only screen and (max-width: 1024px)  
{
  table.width200, .width200 thead, .width200 tbody, .width200 th, .width200 td, .width200 tr { display: block; }
  
  .width200 thead tr { position: absolute;top: -9999px;left: -9999px; }
  
  .width200 tr { border: 1px solid #4CAF50; }
  
  .width200 td { border: none;border-bottom: 1px solid #4CAF50; position: relative;padding-left: 40%;text-align:left }
  
  .width200 td:before {  top: 6px; width: 45%; white-space: nowrap;}

  .width200 td:nth-of-type(1):before { content: "R.F.C.:"; color: #174A84; font-weight: bold; }
  .width200 td:nth-of-type(2):before { content: "Nombre:"; color: #174A84; font-weight: bold;}
  .width200 td:nth-of-type(3):before { content: "Teléfono:"; color: #174A84; font-weight: bold;}
  .width200 td:nth-of-type(4):before { content: "Correo:"; color: #174A84; font-weight: bold;}
  .width200 td:nth-of-type(5):before { content: "Monto a Ahorrar:"; color: #174A84; font-weight: bold;}
  .width200 td:nth-of-type(6):before { content: "Puesto/Ocupación:"; color: #174A84; font-weight: bold;}
  .width200 td:nth-of-type(7):before { content: "Fecha de Registro:"; color: #174A84; font-weight: bold;}
  
  .descarto {display:none;}
  .fontsize {font-size:10px}
}
.searchBox {
  background-image:url('../../../assets/Analyst/search.svg') !important;
  background-position:right !important;
  background-repeat:no-repeat !important;
  border: 1px solid #4CAF50 !important; 
  height: 25px !important;
  outline-offset: 2px; 
  padding:10px 30px 10px 5px !important;
  margin-right: 10px;
}
.buttonShow {
    background-color: #4CAF50; /* Green */
    border: none;
    border-radius: 5px;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 13px;
    font-family:Lato;
    widows: 10px;
    cursor: pointer;
    background-color: #174A84;
}
</style>